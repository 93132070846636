/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: var(--primary);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: var(--primaryHover);
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  z-index: 997;
  background: rgba(0, 0, 0, 0.8);
  height: 80px;
}

#header.header-transparent {
  background: transparent;
}

#header.header-scrolled {
  background: rgba(0, 0, 0, 0.8);
  height: 60px;
}

#header .logo {
  font-size: 32px;
  margin: 0;
  padding: 8px 0 0 10px;
  font-weight: 700;
  letter-spacing: 3px;
  border-left: 4px solid var(--primary);
}

#header .logo a {
  color: #fff;
}

#header .logo img {
  max-height: 40px;
}

#header .logo a .logo-text {
  position: relative;
  font-size: 25px;
  background: -webkit-linear-gradient(360deg, var(--primary), #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#header .logo a .sub-logo {
  position: absolute;
  top: -42%;
  left: 2%;
  font-size: 12px;
  -webkit-text-fill-color: #fff;
  letter-spacing: 8px;
  transition: ease-in-out 0.3s;
}

@media (max-width: 992px) {
  #header .logo {
    padding: 0 0 0 10px;
    font-size: 28px;
  }
  #header .logo a .logo-text {
    font-size: 28px;
  }

  #header .logo a .sub-logo {
    display: none;
    transition: ease-in-out 0.3s;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
  text-transform: uppercase;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: var(--primary);
}

.navbar .dropdown a {
  transition: 0.3s;
  color: #fff;
}
.navbar .dropdown a:hover {
  color: var(--primary);
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  right: 0;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  text-transform: none;
  color: #666666;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: var(--primary);
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  right: 100px;
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  right: 100px;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
  * Mobile Navigation 
  */
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }
  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(26, 26, 26, 0.9);
  transition: 0.3s;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a {
  padding: 10px 20px;
  font-size: 15px;
  color: #333333;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: var(--primary);
}

.navbar-mobile .getstarted {
  margin: 15px;
}

.navbar-mobile .dropdown a {
  color: #333333;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 999;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: var(--primary);
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  background: #dddddd;
  overflow: hidden;
  position: relative;
}

@media (max-height: 500px) {
  #hero {
    height: 150vh;
  }
}

#hero .carousel,
#hero .carousel-inner,
#hero .carousel-item,
#hero .carousel-item::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

#hero .carousel-item .carousel-img {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  object-position: center !important;
}

#hero .carousel-item::before {
  content: "";
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.6) 15%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0.4) 85%,
    rgba(0, 0, 0, 0.2) 100%
  );
  backdrop-filter: blur(5px);
}

#hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 70px;
  left: 50px;
  right: 50px;
}

#hero .container {
  text-align: center;
}

#hero h2 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 700;
}

@media (max-width: 768px) {
  #hero h2 {
    font-size: 28px;
  }
}

#hero p {
  width: 80%;
  margin: 0 auto 30px auto;
  color: #fff;
}

@media (min-width: 1024px) {
  #hero p {
    width: 60%;
  }
}

#hero .carousel-fade {
  overflow: hidden;
}

#hero .carousel-fade .carousel-inner .carousel-item {
  transition-property: opacity;
}

#hero .carousel-fade .carousel-inner .carousel-item,
#hero .carousel-fade .carousel-inner .active.carousel-item-start,
#hero .carousel-fade .carousel-inner .active.carousel-item-end {
  opacity: 0;
}

#hero .carousel-fade .carousel-inner .active,
#hero .carousel-fade .carousel-inner .carousel-item-next.carousel-item-start,
#hero .carousel-fade .carousel-inner .carousel-item-prev.carousel-item-end {
  opacity: 1;
  transition: 0.5s;
}

#hero .carousel-fade .carousel-inner .carousel-item-next,
#hero .carousel-fade .carousel-inner .carousel-item-prev,
#hero .carousel-fade .carousel-inner .active.carousel-item-start,
#hero .carousel-fade .carousel-inner .active.carousel-item-end {
  left: 0;
  transform: translate3d(0, 0, 0);
}

#hero .carousel-control-prev,
#hero .carousel-control-next {
  width: 10%;
}

@media (min-width: 1024px) {
  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 5%;
  }
}

#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  background: none;
  font-size: 32px;
  line-height: 1;
}

#hero .carousel-indicators li {
  cursor: pointer;
}

#hero .btn-get-started {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 32px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  color: #fff;
  background: var(--primary);
  cursor: pointer;
}

#hero .btn-get-started:hover {
  background: #fff;
  color: var(--primary);
}

/*--------------------------------------------------------------
  # Sections
  --------------------------------------------------------------*/
section {
  overflow: hidden;
}

/* Sections Header
--------------------------------*/
.section-header h3 {
  font-size: 32px;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
}

.section-header h3::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-header h3::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: var(--primary);
  bottom: 0;
  left: calc(50% - 20px);
}

.section-header p {
  text-align: center;
  padding-bottom: 30px;
  color: #333;
}

/* Section with background
  --------------------------------*/
.section-bg {
  background: #f7f7f7;
}

/*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
.breadcrumbs {
  padding: 20px 0;
  background-color: #f7f7f7;
  min-height: 40px;
  margin-top: 80px;
}

.breadcrumbs h2 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 400;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6c757d;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
}

/* Featured Services Section
--------------------------------*/
#featured-services {
  background: #131313;
  /* background: url("./assets/feature/feature-bg-6.jpeg") center no-repeat;
  background-size: cover;
  position: relative; */
}

#featured-services .box {
  position: relative;
  background: rgba(225, 225, 225, 0.1);
  box-shadow: 5px 5px 35px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  padding: 30px 20px;
  text-align: center;
}

#featured-services .box:first-child {
  box-shadow: -5px 5px 35px rgba(0, 0, 0, 0.2);
}

#featured-services .box-bg {
  background: linear-gradient(
    135deg,
    rgba(225, 225, 225, 0.3) 0%,
    rgba(225, 225, 225, 0.1) 15%,
    transparent 50%,
    transparent 85%,
    rgba(225, 225, 225, 0.3) 100%
  );
}

#featured-services i {
  color: var(--primary);
  font-size: 48px;
  display: inline-block;
  line-height: 1;
}

#featured-services h4 {
  color: #ffffff;
  font-weight: 400;
  margin: 15px 0;
  font-size: 18px;
}

#featured-services h4 a {
  cursor: pointer;
  color: #ffffff;
}

#featured-services h4 a:hover {
  color: var(--primary);
}

#featured-services p {
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 0;
}

/* About Us Section
--------------------------------*/
#about {
  background: url("./assets/about/about-bg.jpg") center top no-repeat fixed;
  background-size: cover;
  padding: 60px 0 40px 0;
  position: relative;
}

#about::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.92);
  z-index: 9;
}

#about .container {
  position: relative;
  z-index: 10;
}

#about .about-col {
  background: #fff;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
}

#about .about-col .img {
  position: relative;
  height: 40%;
}

#about .about-col .img img {
  border-radius: 4px 4px 0 0;
}

#about .about-col .icon {
  width: 64px;
  height: 64px;
  text-align: center;
  position: absolute;
  background-color: var(--primary);
  border-radius: 50%;
  border: 4px solid #fff;
  left: calc(50% - 32px);
  bottom: -30px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

#about .about-col i {
  font-size: 28px;
  line-height: 0;
  color: #fff;
  transition: 0.3s;
}

#about .about-col:hover .icon {
  background-color: #fff;
}

#about .about-col:hover i {
  color: var(--primary);
}

#about .about-col h2 {
  color: #000;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  padding: 0;
  margin: 40px 0 12px 0;
}

#about .about-col h2 a {
  color: #000;
}

#about .about-col h2 a:hover {
  color: var(--primary);
}

#about .about-col p {
  font-size: 14px;
  line-height: 24px;
  color: #333;
  margin-bottom: 0;
  padding: 0 20px 20px 20px;
}

/* Services Section
--------------------------------*/
#services {
  background: #fff;
  background-size: cover;
  padding: 60px 0 40px 0;
}

#services .box {
  margin-bottom: 30px;
}

#services .icon {
  float: left;
}

#services .icon i {
  color: var(--primary);
  font-size: 36px;
  line-height: 0;
  transition: 0.5s;
}

#services .title {
  margin-left: 60px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

#services .title a {
  cursor: pointer;
  color: #111;
}

#services .box:hover .title a {
  color: var(--primary);
}

#services .description {
  font-size: 14px;
  margin-left: 60px;
  line-height: 24px;
  margin-bottom: 0;
}

/* Call To Action Section
--------------------------------*/
#call-to-action {
  background: linear-gradient(rgba(237, 7, 107, 0.3), rgba(0, 0, 0, 0.3)),
    url("./assets/callToAction/call-to-action-bg.jpg") fixed center center;
  background-size: cover;
  padding: 60px 0;
}

#call-to-action h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

#call-to-action p {
  color: #fff;
}

#call-to-action .cta-btn {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 25px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
  cursor: pointer;
}

#call-to-action .cta-btn:hover {
  background: #fff;
  border: 2px solid #fff;
  color: var(--primaryHover);
  transition: 0.5s;
}

/* Call to action Modal 
------------------------------*/
#callToActionModal .modal-title {
  color: var(--primary);
  font-weight: 600;
  font-size: 1.3rem;
}

#callToActionModal .modal-form input {
  padding: 10px 14px;
  border-radius: 0;
  box-shadow: none;
  font-size: 15px;
}

#callToActionModal .modal-form button[type="submit"] {
  background: var(--primary);
  border: 0;
  padding: 10px 30px;
  color: #fff;
  border-radius: 50px;
  transition: 0.4s;
  cursor: pointer;
}

#callToActionModal .modal-form button[type="submit"]:hover {
  background: var(--primaryHover);
}

#callToActionModal button.modal-close {
  background: var(--primary);
  border: 0;
  padding: 6px 20px;
  color: #fff;
  border-radius: 50px;
  transition: 0.4s;
  cursor: pointer;
}

/* Our Skills Section
--------------------------------*/
#skills {
  padding: 60px 0;
}

#skills .progress {
  height: 35px;
  margin-bottom: 10px;
  border-radius: 0;
}

#skills .progress .skill {
  line-height: 35px;
  padding: 0;
  margin: 0 0 0 20px;
  text-transform: uppercase;
}

#skills .progress .skill .val {
  float: right;
  font-style: normal;
  margin: 0 20px 0 0;
}

#skills .progress-bar {
  /* width: 1px; */
  text-align: left;
  transition: 1s;
}

/* Facts Section
--------------------------------*/
#facts {
  background: url("./assets/facts/facts-bg.jpg") center top no-repeat fixed;
  background-size: cover;
  padding: 60px 0 0 0;
  position: relative;
}

#facts::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.88);
  z-index: 9;
}

#facts .container {
  position: relative;
  z-index: 10;
}
#facts .container-fluid {
  position: relative;
  z-index: 10;
}

#facts .counters span {
  font-weight: 600;
  font-size: 48px;
  display: block;
  color: var(--primary);
}

#facts .counters p {
  padding: 0;
  margin: 0 0 20px 0;
  font-size: 14px;
  color: #111;
}

/*--------------------------------------------------------------
# Facts - Info Box
--------------------------------------------------------------*/
.info-box .content {
  padding: 60px 100px 0 100px;
}

.info-box .content h3 {
  font-weight: 400;
  font-size: 34px;
}

.info-box .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}

.info-box .content p {
  font-size: 15px;
  color: #716565;
}

.info-box .facts-img {
  background: url("./assets//facts//companybuilding.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 400px;
}

.info-box .accordion-list {
  padding: 0 100px 60px 100px;
}

.info-box .accordion-list ul {
  padding: 0;
  list-style: none;
}

.info-box .accordion-list li + li {
  margin-top: 15px;
}

.info-box .accordion-list li {
  padding: 20px;
  background: #f2f5f4;
  border-radius: 5px;
}

.info-box .accordion-list a {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 30px;
  outline: none;
  transition: ease-in-out 0.3s;
  cursor: pointer;
  color: #343a40 !important;
}

.info-box .accordion-list a:hover {
  color: var(--primary);
}

.info-box .accordion-list span {
  color: var(--primary);
  font-weight: 600;
  font-size: 18px;
  padding-right: 10px;
}

.info-box .accordion-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.info-box .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.info-box .accordion-list .icon-show {
  display: none;
}

.info-box .accordion-list a.collapsed {
  color: #343a40 !important;
}

.info-box .accordion-list a.collapsed:hover {
  color: var(--primary) !important;
}

.info-box .accordion-list a.collapsed .icon-show {
  display: inline-block;
}

.info-box .accordion-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1024px) {
  .info-box .content,
  .info-box .accordion-list {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .info-box .content {
    padding-top: 30px;
  }
  .info-box .accordion-list {
    padding-bottom: 30px;
  }
}

/* Portfolio Section
--------------------------------*/
#portfolio {
  padding: 60px 0;
}

#portfolio #portfolio-flters {
  padding: 0;
  margin: 5px 0 35px 0;
  list-style: none;
  text-align: center;
}

#portfolio #portfolio-flters li {
  cursor: pointer;
  margin: 15px 15px 15px 0;
  display: inline-block;
  padding: 10px 20px;
  font-size: 12px;
  line-height: 20px;
  color: #666666;
  border-radius: 4px;
  text-transform: uppercase;
  background: #fff;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

#portfolio #portfolio-flters li:hover,
#portfolio #portfolio-flters li.filter-active {
  background: var(--primary);
  color: #fff;
}

#portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

#portfolio .portfolio-wrap {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  transition: 0.3s;
}

#portfolio .portfolio-wrap:hover {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
}

#portfolio .portfolio-item {
  position: relative;
  height: 360px;
  overflow: hidden;
}

#portfolio .portfolio-item figure {
  background: #000;
  overflow: hidden;
  height: 240px;
  position: relative;
  border-radius: 4px 4px 0 0;
  margin: 0;
}

#portfolio .portfolio-item figure:hover img {
  opacity: 0.4;
  transition: 0.3s;
}

#portfolio .portfolio-item figure .link-preview,
#portfolio .portfolio-item figure .link-details {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  line-height: 1;
  text-align: center;
  width: 36px;
  height: 36px;
  background: #fff;
  border-radius: 50%;
  transition: 0.2s linear;
}

#portfolio .portfolio-item figure .link-preview i,
#portfolio .portfolio-item figure .link-details i {
  font-size: 22px;
  color: #333;
  line-height: 0;
}

#portfolio .portfolio-item figure .link-preview:hover,
#portfolio .portfolio-item figure .link-details:hover {
  background: var(--primary);
}

#portfolio .portfolio-item figure .link-preview:hover i,
#portfolio .portfolio-item figure .link-details:hover i {
  color: #fff;
}

#portfolio .portfolio-item figure .link-preview {
  left: calc(50% - 38px);
  top: calc(50% - 18px);
}

#portfolio .portfolio-item figure .link-details {
  right: calc(50% - 38px);
  top: calc(50% - 18px);
}

#portfolio .portfolio-item figure:hover .link-preview {
  opacity: 1;
  left: calc(50% - 44px);
}

#portfolio .portfolio-item figure:hover .link-details {
  cursor: pointer;
  opacity: 1;
  right: calc(50% - 44px);
}

#portfolio .portfolio-item .portfolio-info {
  background: #fff;
  text-align: center;
  padding: 30px;
  height: 90px;
  border-radius: 0 0 3px 3px;
}

#portfolio .portfolio-item .portfolio-info h4 {
  font-size: 18px;
  line-height: 1px;
  font-weight: 700;
  margin-bottom: 18px;
  padding-bottom: 0;
}

#portfolio .portfolio-item .portfolio-info h4 a {
  color: #333;
  cursor: pointer;
}

#portfolio .portfolio-item .portfolio-info h4 a:hover {
  color: var(--primary);
}

#portfolio .portfolio-item .portfolio-info p {
  padding: 0;
  margin: 0;
  color: #b8b8b8;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid var(--primary);
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: var(--primary);
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(51, 51, 51, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/* Clients Section
--------------------------------*/
#clients {
  padding: 60px 0 30px 0;
}

#clients img {
  opacity: 0.5;
  transition: 0.3s;
}

#clients img:hover {
  opacity: 1;
}

#clients .swiper-container {
  padding-top: 10px !important;
  padding-bottom: 40px !important;
}

#clients .swiper-pagination {
  bottom: -5px !important;
}

#clients .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid var(--primary);
}

#clients .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--primary);
}

/* Testimonials Section
--------------------------------*/
#testimonials {
  padding: 60px 0;
}

#testimonials .section-header {
  margin-bottom: 40px;
}

#testimonials .testimonial-item {
  text-align: center;
}

#testimonials .testimonial-item .testimonial-img {
  width: 120px;
  border-radius: 50%;
  border: 4px solid #fff;
  margin: 0 auto;
}

#testimonials .testimonial-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

#testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0 0 15px 0;
}

#testimonials .testimonial-item .quote-sign-left {
  margin-top: -15px;
  padding-right: 10px;
  display: inline-block;
  width: 37px;
}

#testimonials .testimonial-item .quote-sign-right {
  margin-bottom: -15px;
  padding-left: 10px;
  display: inline-block;
  max-width: 100%;
  width: 37px;
}

#testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
}

@media (min-width: 992px) {
  #testimonials .testimonial-item p {
    width: 80%;
  }
}

#testimonials .swiper-container {
  padding-top: 10px !important;
  padding-bottom: 20px !important;
}

#testimonials .swiper-pagination {
  bottom: -5px;
}

#testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid var(--primary);
}

#testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--primary);
}

/* Training Section
--------------------------------*/
#training {
  background: #fff;
  padding: 60px 0;
}

.pricing .box {
  padding: 20px;
  background: #fff;
  text-align: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.pricing h3 {
  font-weight: 400;
  margin: -20px -20px 20px -20px;
  padding: 20px 15px;
  font-size: 16px;
  font-weight: 600;
  color: #777777;
  background: #f8f8f8;
}

.pricing h4 {
  font-size: 36px;
  color: var(--primary);
  font-weight: 600;
  margin-bottom: 20px;
}

.pricing h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #444444;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.pricing ul li {
  padding-bottom: 16px;
}

.pricing ul i {
  color: var(--primary);
  font-size: 18px;
  padding-right: 4px;
}

.pricing ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing .btn-wrap {
  margin: 20px -20px -20px -20px;
  padding: 20px 15px;
  background: #f8f8f8;
  text-align: center;
}

.pricing .btn-buy {
  background: var(--primary);
  display: inline-block;
  padding: 8px 35px 9px 35px;
  border-radius: 50px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-weight: 600;
  transition: 0.3s;
  cursor: pointer;
}

.pricing .btn-buy:hover {
  background: var(--primaryHover);
}

.pricing .featured h3 {
  color: #fff;
  background: var(--primary);
}

.pricing .advanced {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: var(--primary);
  color: #fff;
}

/* Contact Section
--------------------------------*/
#contact {
  padding: 60px 0;
}

#contact .map {
  width: 100%;
}

#contact .contact-info {
  margin-bottom: 20px;
  text-align: center;
}

#contact .contact-info i {
  font-size: 48px;
  display: inline-block;
  margin-bottom: 10px;
  color: var(--primary);
}

#contact .contact-info address,
#contact .contact-info p {
  margin-bottom: 0;
  color: #000;
}

#contact .contact-info h3 {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: bold;
  text-transform: uppercase;
  color: #999;
}

#contact .contact-info a {
  color: #000;
}

#contact .contact-info a:hover {
  color: var(--primary);
}

#contact .contact-address,
#contact .contact-phone,
#contact .contact-email {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  #contact .contact-address,
  #contact .contact-phone,
  #contact .contact-email {
    padding: 20px 0;
  }
}

@media (min-width: 768px) {
  #contact .contact-phone {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
}

#contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
  background: #fff;
}

#contact .php-email-form .error-message {
  display: block;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
  transition: ease-in-out 0.5s;
}

#contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

#contact .php-email-form .sent-message {
  display: block;
  color: #fff;
  background: var(--primary);
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

#contact .php-email-form .loading {
  display: block;
  background: #fff;
  text-align: center;
  padding: 15px;
}

#contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid var(--primary);
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

#contact .php-email-form .form-group {
  margin-bottom: 20px;
}

#contact .php-email-form input,
#contact .php-email-form textarea {
  padding: 10px 14px;
  border-radius: 0;
  box-shadow: none;
  font-size: 15px;
}

#contact .php-email-form input::focus,
#contact .php-email-form textarea::focus {
  background-color: var(--primary);
}

#contact .php-email-form button[type="submit"] {
  background: var(--primary);
  border: 0;
  padding: 10px 30px;
  color: #fff;
  border-radius: 50px;
  transition: 0.4s;
  cursor: pointer;
}

#contact .php-email-form button[type="submit"]:hover {
  background: var(--primaryHover);
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #000;
  padding: 0 0 30px 0;
  color: #eee;
  font-size: 14px;
}

#footer .footer-top {
  background: #111;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 30px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 10px;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 3px;
  border-left: 4px solid var(--primary);
}
#footer .footer-top .footer-info h3 a {
  color: #fff;
}

#footer .footer-top .footer-info h3 a .logo-text {
  background: -webkit-linear-gradient(360deg, var(--primary), #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 0;
  color: #eee;
}

#footer .footer-top .social-links {
  margin-top: 30px;
}

#footer .footer-top .social-links a {
  display: inline-block;
  background: #333;
  color: #eee;
  line-height: 1;
  margin-right: 7px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#footer .footer-top .social-links a i {
  line-height: 0;
  font-size: 16px;
}

#footer .footer-top .social-links a:hover {
  background: var(--primary);
  color: #fff;
}

#footer .footer-top h4 {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top h4::before,
#footer .footer-top h4::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
}

#footer .footer-top h4::before {
  right: 0;
  background: #555;
}

#footer .footer-top h4::after {
  background: var(--primary);
  width: 60px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 8px;
  color: #ddd;
}

#footer .footer-top .footer-links ul li {
  border-bottom: 1px solid #333;
  padding: 10px 0;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 10px;
}

#footer .footer-top .footer-links ul a {
  color: #eee;
}

#footer .footer-top .footer-links ul a:hover {
  color: var(--primary);
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
  padding-top: 10px;
  line-height: 26px;
  letter-spacing: 1px;
}

#footer .footer-top .footer-newsletter p {
  padding-top: 10px;
  line-height: 25px;
}

#footer .footer-top .footer-newsletter .wechat {
  width: 30%;
}

#footer .footer-top .footer-newsletter input[type="email"] {
  border: 0;
  padding: 6px 8px;
  width: 65%;
}

#footer .footer-top .footer-newsletter input[type="submit"] {
  background: var(--primary);
  border: 0;
  width: 35%;
  padding: 6px 0;
  text-align: center;
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
}

#footer .footer-top .footer-newsletter input[type="submit"]:hover {
  background: var(--primaryHover);
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
  letter-spacing: 1px;
}

#footer .credits {
  text-align: center;
  font-size: 13px;
  letter-spacing: 1px;
  color: #ddd;
}

#footer .credits a {
  color: var(--primary);
  font-weight: 600;
}
