@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600;900&family=ZCOOL+XiaoWei&display=swap");

:root {
  --primary: #f51367;
  --primaryHover: #d7004f;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #fff;
  color: #666666;
  font-family: "ZCOOL XiaoWei", serif !important;
  font-family: "Poppins", sans-serif !important;
}

a {
  color: var(--primary);
  transition: 0.5s;
  text-decoration: none !important;
}

a:hover,
a:active,
a:focus {
  color: var(--primaryHover);
  outline: none;
  text-decoration: none !important;
}

p {
  padding: 0;
  margin: 0 0 30px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
}
